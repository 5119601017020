var observer = new IntersectionObserver(
  function (entries) {
    // no intersection with screen
    if (entries[0].intersectionRatio === 0)
      document.querySelector(".nav-container").classList.add("is-stuck");
    // fully intersects with screen
    else if (entries[0].intersectionRatio === 1)
      document.querySelector(".nav-container").classList.remove("is-stuck");
  },
  { threshold: [0, 1] }
);

if (document.querySelector(".navbar-trigger"))
  observer.observe(document.querySelector(".navbar-trigger"));

if (document.querySelector(".rellax"))
  new Rellax(".rellax");

if (document.querySelector(".lazy"))
  new LazyLoad();

var eventsCarousel = document.getElementById("events-carousel");
if (eventsCarousel) {
  eventsCarousel.addEventListener("slide.bs.carousel", (event) => {
    var prevButtons = document.querySelectorAll("[aria-label='Previous']");
    var nextButtons = document.querySelectorAll("[aria-label='Next']");

    if (event.to === 0) {
      prevButtons.forEach((el) => {
        el.classList.add("d-none");
      });
      nextButtons.forEach((el) => {
        el.classList.remove("d-none");
      });
    } else if (event.to === event.relatedTarget.parentNode.children.length - 1) {
      nextButtons.forEach((el) => {
        el.classList.add("d-none");
      });
      prevButtons.forEach((el) => {
        el.classList.remove("d-none");
      });
    } else {
      prevButtons.forEach((el) => {
        el.classList.remove("d-none");
      });
      nextButtons.forEach((el) => {
        el.classList.remove("d-none");
      });
    }
  });

  eventsCarousel.addEventListener("slide.bs.carousel", (event) => {
    var sectionTitles = document.querySelectorAll(".section-title");
    sectionTitles.forEach((title) => {
      title.innerHTML = event.relatedTarget.dataset.month;
    });
  });
}

function acceptCookies() {
  document.cookie = "cookieconsent_dismissed=1;secure";
  location.reload();
}